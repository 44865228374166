import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel, Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
  document.title = "Bigozon Home";
  const [tradingWallet, setTradingWallet] = useState(0);
  let incrementInterval;
  const [loading, setLoading] = useState(true);


   const [homedata, setHomeData] = useState([]);  
  /*.....product code........*/
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setHomeData(response.data.data_info)
         setTradingWallet(response.data.trading_wallet)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);



    

return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>

           <Col xl="12" className="p-0" >

                <div className="col-xl-12 mt-4">
                  <div className="row mydivpadding">
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                              
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.balance }</h2>
                              <p className="mb-0 text-nowrap">Main Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="4" width="20" height="16" rx="2"/><path d="M7 15h0M2 9.5h20"/></svg>                              
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.reserve_balance }</h2>
                              <p className="mb-0 text-nowrap ">Deposit Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5"/><path d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z"/></svg>                              
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.investing_wallet }</h2>
                              <p className="mb-0 text-nowrap ">Principal Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 20" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>                              
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { tradingWallet }</h2>
                              <p className="mb-0">Live Investing</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg"  width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.5 12H12V2.5"/><circle cx="12" cy="12" r="10"/></svg>                              

                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ 0</h2>
                              <p className="mb-0">Promo Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path><polygon points="12 15 17 21 7 21 12 15"></polygon></svg>
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.total_deposit }</h2>
                              <p className="mb-0">Total Deposit</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20.2 7.8l-7.7 7.7-4-4-5.7 5.7"/><path d="M15 7h6v6"/></svg>
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.total_Income }</h2>
                              <p className="mb-0">Total Income</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 17l5-5-5-5"/><path d="M13.8 12H3m9 10a10 10 0 1 0 0-20"/></svg>
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600">৳ { homedata.total_withdraw }</h2>
                              <p className="mb-0">Total Withdrawal</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

             </Col>


          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;